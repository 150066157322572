import './App.css';
import Login from './componentes/Login/Login';
import {useState} from 'react';
import VentanaPrincipal from './componentes/VentanaPrincipal/VentanaPrincipal';
import FormularioLogin from './componentes/FormularioLogin/FormularioLogin';


function App() {

    const [userData,setUserData] = useState({});


    const loginSuccess = (respuesta) => {
        setUserData(respuesta.data);
    };

    const loginError = (respuesta) => {
        alert("hola");
        alert(respuesta.message);
    };
    

    return(
        <div className="App">
            {userData.id ? <VentanaPrincipal userData={userData} /> : <Login><FormularioLogin loginSuccess={loginSuccess} loginError={loginError} /></Login>}
        </div>
    );
    
}

export default App;
