import React,{useState} from "react";
import './FormularioLogin.css';
import PropTypes from 'prop-types';
import configApi from '../../configApi.json';

export default function FormularioLogin(props){

    const [user,setUsuario]=useState("");
    const [password,setPassword]=useState("");
    
    
    const validarCredenciales=()=>{

        let data={user,password};

        fetch(configApi.domain+"clientesEvoluz/api/obtenerUsuario.php",{
            method: 'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response=>response.json())
        .then((json_respuesta)=>{
            if(json_respuesta.status==="success"){
                props.loginSuccess(json_respuesta);
            }else{
                props.loginError(json_respuesta);
            }
        });
        
    }

    return(
        <div className="formularioLogin">
            <p className="txtBienvenido">{props.txtBienvenida}</p>
            <hr className="hr1"/>
            <input type="text" className="inputFormularioLogin" placeholder="Usuario" onChange={(e)=>{setUsuario(e.target.value)}} />
            <input type="password" className="inputFormularioLogin" placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}} />
            <div className="gridAccionesPassword">
                <div>
                    <input type="checkbox" name="checkRecuerdame" id="checkRecuerdame" />
                    <label htmlFor="checkRecuerdame">Recordarme</label>
                </div>
                <div>
                    <p className="txtRecuperarPassword">¿Olvidaste tu contraseña?</p>
                </div>
            </div>            
            <button className="btnIniciarSesion" onClick={validarCredenciales}>{props.txtBtn}</button>
            <hr className="hr2"/>
        </div>
    );
}

FormularioLogin.propTypes={
    loginSuccess:PropTypes.func.isRequired,
};

FormularioLogin.defaultProps={
    type: "FormularioLogin",
    numberOfInputs: 2,
    name: "Formulario Login1",
    txtBtn: "Iniciar Sesión",
    txtBienvenida: "Bienvenido",
    loginSuccess: (respuesta)=>{console.log(respuesta)},
    loginError: (respuesta)=>{console.log(respuesta)}
}