import React from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import imgLogo from '../../img/logo.png';

function Login({children}){
    
    return(
        <div className='login'>
            {children}
            <div className='contImgLogo'>
                <img src={imgLogo} alt='Logo' />
            </div>
        </div>
    );
    
}

Login.propTypes={
    children:PropTypes.element.isRequired,
};

Login.defaultProps={
    type: "Login"
};

export default Login;