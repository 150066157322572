import React from 'react';
import "./Paginador.css";
import PropTypes from 'prop-types';

export const Paginador = ({nPaginas,onChange,paginaSeleccionada,nPaginasMax}) => {



    const manejarClick = (pagina) => {
        onChange(pagina++);
    };

    const mostrarPaginas = () =>{
        let arrPaginas=[];

        if(nPaginas<=nPaginasMax && nPaginas>0){
            
            for(let i=0;i<nPaginas;i++){
                arrPaginas.push(<a href="#tituloListas" key={i} ><li onClick={()=>manejarClick(i)} className={paginaSeleccionada===i ? "seleccionada" : null}>{i+1}</li></a>);
            }

        }else if(nPaginas=== 0){

            arrPaginas.push(<li key={"nd"} className="seleccionada">1</li>);

        }else{


            let posicionPaginaCentral= nPaginasMax%2 === 0 ? nPaginasMax/2 : Math.floor(nPaginasMax/2);
            let paginaInicial = paginaSeleccionada - (posicionPaginaCentral );
            let paginaFinal = paginaSeleccionada + (posicionPaginaCentral + (nPaginasMax%2 === 0 ? 0 : 1) );

            if((paginaSeleccionada + (posicionPaginaCentral + (nPaginasMax%2 === 0 ? 0 : 1))) > nPaginas){
                paginaFinal=nPaginas;
                paginaInicial=paginaFinal-nPaginasMax;
            }

            for(let i = (paginaInicial > 2 ? paginaInicial : 0) ; i < (paginaInicial > 2 ? paginaFinal : nPaginasMax) ; i++){
                arrPaginas.push(<a href="#tituloListas" key={i} ><li onClick={()=>manejarClick(i)} className={paginaSeleccionada===i ? "seleccionada" : null}>{i+1}</li></a>);
            }


        }
        
        return arrPaginas;
    };

    return (
        <div className='paginador'>
            <a className='btnInicioPaginador' onClick={()=>manejarClick(0)} href="#tituloListas"><p>&lt;&lt;</p></a>
            <ul>
                {mostrarPaginas()}
            </ul> 
            <a className='btnFinPaginador' onClick={()=>manejarClick(nPaginas-1)} href="#tituloListas"><p>&gt;&gt;</p></a>           
        </div>
    );

}

Paginador.propTypes = {
    onChange: PropTypes.func.isRequired
};

Paginador.defaultProps = {
    onChange: (pagina) => {
        console.log("Pagina: "+pagina);
    },
    nPaginas: 0,
    paginaSeleccionada:0,
    nPaginasMax:8

};
