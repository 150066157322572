import React, { useState } from 'react';
import './ModalDescargarListas.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import configApi from '../../configApi.json';

const ModalDescargarListas = ({opcionesListaActual}) => {
    const [tipoLista, setTipoLista] = useState("todas");

    const descargarLista = () =>{
        if(tipoLista === "todas"){
            var a = document.createElement('a');

            a.download = true;
            a.target = '_blank';
            a.href= configApi.domain+"clientesEvoluz/api/listasProductos.xlsx";

            a.click();
        }else{
            const data={
                "paginaAMostrar" : "todo",
                "buscador" : opcionesListaActual.parametroBusqueda,
                "proveedorActual" : opcionesListaActual.proveedorActual
            }
            fetch(configApi.domain+"clientesEvoluz/api/obtenerProductosFormatoXLSX.php",{
                method : "POST",
                body: JSON.stringify(data),
                headers:{
                    'Content-Type': 'application/json'
                }
            })
            .then(response=>response.json())
            .then(respuesta=>descargarListaActual(respuesta));
            
        }
    };

    const descargarListaActual = (productos) =>{
        const productosXLSX = productos.data.products;

        var wb = XLSX.utils.book_new();

        wb.Props = {
            Title: "Lista personalizada",
            Subject: "Listas",
            Author: "EVOLUZ SA DE CV"
        };

        wb.SheetNames.push("prueba");

        var ws = XLSX.utils.aoa_to_sheet(productosXLSX);
        var wscols = [
            {wch:10},
            {wch:10},
            {wch:20},
            {wch:20},
            {wch:20},
            {wch:30},
        ];
        ws['!cols'] = wscols;
        wb.Sheets["prueba"] = ws;

        var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'prueba.xlsx');
        
    };

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
    }

    return (
        <div className='modalDescargarLista'>
            <p className='txtDescargarLista'>Descargar Lista</p>
            <div className='contAccionesDescLista'>
                <div className='contTituloDescListas'>
                    <p>Selecciona el tipo de lista que deseas descargar:</p>
                </div>
                <div className='contSelectDescListas'>
                    <select name="" id="" onChange={(e)=>setTipoLista(e.target.value)}>
                        <option value="todas" >Todas las listas</option>
                        <option value="actual" >Lista de busqueda actual</option>
                    </select>
                </div>
                <div className='contBtnDescargarLista'>
                    <button onClick={descargarLista}>Descargar</button>
                </div>
            </div>
        </div>
    );
}

export default ModalDescargarListas