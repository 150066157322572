
import './VentanaPrincipal.css';
import imgLogo from '../../img/logo.png';
import ModuloListas from '../ModuloListas/ModuloListas';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types'; 

function VentanaPrincipal({userData}){

    useEffect(()=>{
    },[]);


    let idModuloActual=1;
    const moduloActual =  ()=>{
        let componenteModuloActual=<></>;
        switch(idModuloActual){
            case 1:
                componenteModuloActual=<ModuloListas />;
            break;
            default:
            break;
        }
        return componenteModuloActual;
    }


    return(
        <div className='ventanaPrincipal'>
            <div className='contenedorOpciones'>
                <div className='contImgLogo'>
                    <img src={imgLogo} alt="logo" />
                </div>
                <div className='contenedorUsuario'>
                    <p>Bienvenido: {userData.usuario}</p>
                    <hr />
                </div>
                <div className='opciones'>
                    <ul>
                        <li>Consultar Listas</li>
                    </ul>
                </div>
            </div>
            <div className='contenedorModulos'>
                {moduloActual()}
            </div>
        </div>
    );
    
}

VentanaPrincipal.propTypes={
    userData: PropTypes.object.isRequired
};

VentanaPrincipal.defaultValues = {
    userData: {}
};


export default VentanaPrincipal;