import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Paginador } from '../Paginador/Paginador';
import ModalDescargarListas from '../ModalDescargarListas/ModalDescargarListas';
import './ModuloListas.css';
import configApi from '../../configApi.json';


function FilaProducto({producto}){
    return (
        <tr key={producto.codigo_producto} className="filaProducto">
            <td>{producto.codigo_producto}</td>
            <td>{producto.descripcion}</td>
            <td>{producto.marca}</td>
            <td>${producto.precio}</td>
        </tr>
    );
}

function ModuloListas(){

    const [productos,setProductos] = useState([]);
    const [nPaginas,setNPaginas]=useState(1);
    const [paginaAMostrar,setPaginaAMostrar] = useState(0);
    const [parametroBusqueda,setParametroBusqueda] = useState("");
    const [listaProveedores,setListaProveedores] = useState([]);
    const [proveedorActual,setProveedorActual] = useState("");
    const [ventanaDescargar, setVentanaDescargar] = useState(false);

    useEffect(()=>{
        obtenerProductos(paginaAMostrar,parametroBusqueda,proveedorActual);
    },[paginaAMostrar, parametroBusqueda, proveedorActual]);

    useEffect(()=>{
        obtenerProveedores();
    },[]);

    const obtenerProductos = (paginaAMostrar,parametroBusqueda,proveedorActual) =>{

        const data={
            "paginaAMostrar" : paginaAMostrar,
            "buscador" : parametroBusqueda,
            "proveedorActual" : proveedorActual
        }

        fetch(configApi.domain+"clientesEvoluz/api/obtenerProductos.php",{
            method : "POST",
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response=>response.json())
        .then(respuesta=>respuesta.status==="success" ? (setProductos(respuesta.data.products),setNPaginas(respuesta.data.pages),console.log(respuesta)) : null);

    }

    const obtenerProveedores = () => {

        const data={
        }

        fetch(configApi.domain+"clientesEvoluz/api/obtenerProveedores.php",{
            method : "POST",
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(respuesta => {
            const proveedores=respuesta.data.proveedores.map(proveedor=>{
                return {
                    "label" : proveedor.codigo_proveedor,
                    "value" : proveedor.codigo_proveedor
                }
            });

            proveedores.unshift({"label":"--TODOS--", "value":"todos"});

            setListaProveedores(proveedores);
        });
    };

    return(
        <div className='moduloListas'>
            <p className='titulo' id="tituloListas">Lista de productos</p>
            <hr />
            <div className='contenedorAcciones'>
                <div>
                    <input type="text" className='txtBuscadorProducto' placeholder='Buscar producto' onChange={(e)=>{setPaginaAMostrar(0);setParametroBusqueda(e.target.value);}} />
                </div>
                <div >
                    <Select options={listaProveedores} defaultValue={{"label":"Seleccionar Marca", "value":"vacio"}}  onChange={(e)=>setProveedorActual(e.value)} ></Select>
                </div>
                <div>
                    <button className='btnDescargarLista' onClick={()=>(ventanaDescargar ? setVentanaDescargar(false) : setVentanaDescargar(true))}>Descargar Lista</button>
                </div>
            </div>
            {ventanaDescargar ? <ModalDescargarListas opcionesListaActual={{parametroBusqueda,proveedorActual}} /> : <></>}
            <hr className='hr1' />
            <div className='contenedorProductos'>
                <table className='tablaProductos' cellPadding='0' cellSpacing='0'>
                    <thead>
                        <tr>
                            <td>Codigo de producto</td>
                            <td>Descripcion</td>
                            <td>Marca</td>
                            <td>Precio</td>
                        </tr>
                    </thead>
                    <tbody>
                        { productos.map( producto => <FilaProducto producto={producto} key={producto.id} /> ) }
                    </tbody>
                </table>
                <br />
                <br />
                <br />
                <hr />
                <Paginador nPaginas={nPaginas} onChange={setPaginaAMostrar} paginaSeleccionada={paginaAMostrar} />

                
                
            </div>
        </div>
    );
}

export default ModuloListas;